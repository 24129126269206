// This project uses a THREE.JS visualization with Angular so the following
// changes are made to enhance performance

/* The flags allowed in zone - flags.ts are listed here.
*
* The following flags will work for all browsers.
*
* // disable patching requestAnimationFrame
* (window as any).__Zone_disable_requestAnimationFrame = true;
*
* // disable patch onProperty such as onclick
* (window as any).__Zone_disable_on_property = true;
*
* // disable patch specified eventNames
* (window as any).__zone_symbol__UNPATCHED_EVENTS =['scroll', 'mousemove'];
*
*  in IE / Edge developer tools, the addEventListener will also be wrapped by zone.js
*  with the following flag, it will bypass`zone.js` patch for IE / Edge
*
* (window as any).__Zone_enable_cross_context_check = true;
*
*/

// disable patching requestAnimationFrame
(window as any).__Zone_disable_requestAnimationFrame = true;

// disable patching specified eventNames
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove', 'touchmove'];